import ResponsiveMenu from "./components/responsive-menu";

/* Cross-browser support for ensuring functions fire when DOM is loaded */
if (document.readyState !== "loading") {
  initCode();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    initCode();
  });
}

/* Functions that fire when the DOM is loaded and ready*/
function initCode() {
  // Tell the document we have loaded JS and can use progressive enhancement
  document.querySelector("body").setAttribute("data-hasjs", true);

  // Run functions when DOM is loaded and parsed
  window.customElements.whenDefined("responsive-menu").then(() => {
    primaryNav(document.querySelector(".nav.nav--primary"));

    // Find language links (english/french) and on click have them set a cookie for the
    // site language
    const langLinks = document.querySelectorAll("a[data-set-language]");
    if (langLinks.length) {
      langLinks.forEach((link) => {
        link.addEventListener("click", (event) => {
          event.preventDefault();
          setLanguagePreference(link.getAttribute("data-set-language"));
        });
      });
    }
  });
}

// Function for language cookie
function setLanguagePreference(language) {
  // Set Netlify's language cookie
  const oneYear = 365 * 24 * 60 * 60;
  document.cookie = `nf_lang=${language}; path=/; max-age=${oneYear}; SameSite=Lax`;

  // Get the current path
  const currentPath = window.location.pathname;

  // If switching to English, strip out the /fr/ prefix
  if (language === "en") {
    const newPath = currentPath.replace(/^\/fr\//, "/");
    window.location.href = newPath || "/";
  } else {
    // If French selected, add /fr/ prefix if not already present
    window.location.href = currentPath.startsWith("/fr/")
      ? currentPath
      : "/fr" + (currentPath === "/" ? "" : currentPath);
  }
}

const primaryNav = function (nav) {
  if (!nav && !nav.nodeType === Node.ELEMENT_NODE) return;

  // Find links with children and change them to mimick a disclosure pattern
  const parentLinks = nav.querySelectorAll("a[data-submenu]");
  let openSubmenu;

  const toggleSubMenu = function (e) {
    e.preventDefault();
    const isOpen = this.getAttribute("aria-expanded") === "true";

    // Close all
    closeAll();

    // Only open if the link clicked wasn't already openw
    !isOpen && this.setAttribute("aria-expanded", "true");
    openSubmenu = this.nextElementSibling;

    window.addEventListener("click", handleClosure);
    window.addEventListener("keyup", handleClosure);
  };

  const closeAll = () => {
    parentLinks.forEach((link) => {
      link.setAttribute("aria-expanded", "false");
    });
  };

  const handleClosure = function (e) {
    const submenuTrigger = openSubmenu.previousElementSibling;

    if (
      (submenuTrigger === e.target && e.type !== "keyup") ||
      (openSubmenu.contains(e.target) && e.type === "click") ||
      (e.type === "keyup" && e.key !== "Escape")
    )
      return;

    closeAll();

    if (e.key === "Escape") {
      submenuTrigger.focus();
    }

    window.removeEventListener("click", handleClosure);
    window.removeEventListener("keyup", handleClosure);
  };

  // Init all links
  parentLinks.forEach((link) => {
    if (!link.hasAttribute("data-submenu")) return;
    const id = link.getAttribute("data-submenu");
    link.setAttribute("role", "button");
    link.setAttribute("aria-expanded", "false");
    link.setAttribute("aria-controls", id);
    link.nextElementSibling.setAttribute("id", id);

    link.addEventListener("click", toggleSubMenu);
  });
};
